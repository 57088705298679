import React, { useState, useEffect, lazy, Suspense, Fragment } from 'react';
import { Col, Row, Container, Card, Nav } from 'react-bootstrap';
import { Link as ScrollLink, Element } from 'react-scroll';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GKAccordionDefault from 'components/common/accordions/GKAccordionDefault';
import useLogin from 'hooks/useLogin';
import useCourseContent from 'hooks/useCourseContent';
import useCourseIndex from 'hooks/useCourseIndex';
import useLessonCompletion from 'hooks/useLessonCompletion';

const Header = lazy(() => import('../components/course-page/Header'));
const Sidebar = lazy(() => import('../components/course-page/Sidebar'));
const Footer = lazy(() => import('components/layouts/footer/Footer'));

const CoursePage = () => {
  const { courseId, lessonId } = useParams();
  const { t } = useTranslation();
  const { user } = useLogin();
  const [completedLessons, setCompletedLessons] = useState([]);
  const [activeTab, setActiveTab] = useState('course-description'); 
  const { isLessonCompleted } = useLessonCompletion(lessonId, courseId, user, setCompletedLessons);

  const { courseContent, error: contentError } = useCourseContent(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses/${courseId}/content`);
  const { courseIndex, error: indexError } = useCourseIndex(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses/${courseId}/lessons`);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (courseContent) {
      const descriptionContainer = document.getElementById('description-container');
      if (descriptionContainer) {
        const sanitizedContent = courseContent.trim().replace(/\n\s*\n/g, '\n');
        descriptionContainer.innerHTML = sanitizedContent;

        const scripts = descriptionContainer.querySelectorAll('script');
        scripts.forEach((oldScript) => {
          const newScript = document.createElement('script');
          Array.from(oldScript.attributes).forEach(attr =>
            newScript.setAttribute(attr.name, attr.value)
          );
          newScript.textContent = oldScript.textContent;
          oldScript.parentNode.replaceChild(newScript, oldScript);
        });
      }
    }
  }, [courseContent]);

  if (contentError || indexError) {
    return <div>{t('error_loading_data')}</div>;
  }

  return (
    <Fragment>
      <Suspense fallback={<div>{t('loading_header')}</div>}>
        <Header courseId={courseId} />
      </Suspense>

      <section className="pb-10">
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 1 }} md={12} sm={12} className="mt-n10 mb-4 mb-lg-0">
              <Card className="mb-4">
                <Card.Body className="p-0 mb-4">
                  <Nav className="nav-lb-tab d-none d-lg-flex" role="tablist">
                    <Nav.Item>
                      <ScrollLink
                        to="course-description"
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className={`nav-link ${activeTab === 'course-description' ? 'active' : ''}`}
                        onClick={() => handleTabClick('course-description')}
                      >
                        {t('course_description')}
                      </ScrollLink>
                    </Nav.Item>
                    <Nav.Item>
                      <ScrollLink
                        to="course-content"
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className={`nav-link ${activeTab === 'course-content' ? 'active' : ''}`}
                        onClick={() => handleTabClick('course-content')}
                      >
                        {t('course_content')}
                      </ScrollLink>
                    </Nav.Item>
                  </Nav>
                  <Element name="course-description" className="p-4">
                    <h2>{t('course_description')}</h2>
                    <Suspense fallback={<p>{t('loading_course_content')}</p>}>
                      {courseContent ? (
                        <div id="description-container"></div>
                      ) : (
                        <p>{t('loading_course_content')}</p>
                      )}
                    </Suspense>
                  </Element>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body className="p-4">
                  <Element name="course-content">
                    <h2>{t('course_content')}</h2>
                    {courseIndex ? (
                      <GKAccordionDefault
                        accordionItems={courseIndex}
                        itemClass="px-0"
                        courseId={courseId}
                        completedLessons={user ? completedLessons : []}
                      />
                    ) : (
                      <p>{t('loading_course_content')}</p>
                    )}
                  </Element>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="mt-lg-n22 d-none d-lg-block">
              <Suspense fallback={<div>{t('loading_sidebar')}</div>}>
                <Sidebar courseId={courseId} userId={user ? user.id : null} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </section>

      <Suspense fallback={<div>{t('loading_footer')}</div>}>
        <Footer />
      </Suspense>
    </Fragment>
  );
};

export default CoursePage;